import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { SEO, Text, Flex, Box, Button, BackgroundImage } from "components";
import { useIntl } from "hooks";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Background = styled.div`
  height: 100vh;
  min-width: 100%;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  overflow: hidden;
`;

const Overlay = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
`;

const TextFlex = styled(Flex)`
  border-radius: 9px;
`;

const Feature = styled.div`
  max-width: 800px;
  color: ${p => p.theme.colors.black};
  text-align: center;
  margin-bottom: 40px;
  h2 {
    margin: 15px 0;
    &::first-letter {
      color: ${p => p.theme.colors.brand};
    }
  }
  p {
    line-height: 1.4;
    max-width: 600px;
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const IndexPage = () => {
  const locale = useIntl();
  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "f1.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          nl: contentfulAboutUs(node_locale: { regex: "/nl/" }) {
            button
            title
            feature1 {
              json
            }
          }
          en: contentfulAboutUs(node_locale: { regex: "/en/" }) {
            button
            title
            feature1 {
              json
            }
          }
        }
      `}
      render={data => {
        console.log(data);
        const content = data[locale];
        console.log(documentToReactComponents(content.feature1.json));
        return (
          <>
            <SEO title="About" />
            <Background>
              <BackgroundImage
                durationFadeIn={100}
                loading="eager"
                fluid={data.placeholderImage.childImageSharp.fluid}
              />
              <Overlay />
            </Background>
            <Flex
              width="100%"
              mt={{ _: "100px", sm: "200px" }}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              px={{ _: "20px", sm: "80px", sm: "160px" }}
            >
              <Text
                color="white"
                mb={{ _: "10px", sm: "20px" }}
                as="h1"
                fontSize={{ _: "50px", sm: "70px" }}
              >
                {content.title}
              </Text>
              <TextFlex
                flexDirection="column"
                p="30px 60px"
                maxWidth="900px"
                bg="rgba(255,255,255,0.8)"
                mt="50px"
                mb="200px"
              >
                <Feature>
                  {documentToReactComponents(content.feature1.json)}
                </Feature>
                <Feature>
                  {documentToReactComponents(content.feature1.json)}
                </Feature>
                <Button link to="/contact" mt="40px" secondary>
                  {content.button}
                </Button>
              </TextFlex>
            </Flex>
          </>
        );
      }}
    />
  );
};

export default IndexPage;
